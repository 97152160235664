<template>
  <div id="app">
    <van-pull-refresh :disabled="$route.path.indexOf('sign') != -1 ? true : false" :loosing-text="$t('loosing_text')"
      :pulling-text="$t('pulling_text')" :loading-text="$t('loading')" v-model="isLoading" @refresh="onRefresh">
      <router-view />
    </van-pull-refresh>
    <div v-if="showCover" class="white-cover"></div>
  </div>
</template>

<script>
import Web3 from "web3";
import JSEncrypt from "jsencrypt";
import { Dialog, Toast } from "vant";

let getTimer = null;
let timer = null;

export default {
  data() {
    return {
      change: false,
      isLoading: false,
      inviteCode: "",
      userAddress: "",
      Inval: null,
      showCover: true,
      isBscMainnet: false,
      chainId: 1,
    };
  },
  watch: {
    
  },
  async created() {
    Toast.setDefaultOptions("loading", {
      forbidClick: true,
      duration: 0,
      overlay: true,
      overlayStyle: { background: "#fff" },
    });
  },
  async mounted() {
    this.$store.commit("changeRequestStatus", false);

    this.$Toast.loading({
      overlay: true,
      forbidClick: true,
      duration: 0,
      className: "loadingBg",
    });
    let timeout = setTimeout(() => {
      if (
        !(typeof window.ethereum !== "undefined" && window.ethereum.chainId != "0x2b6653dc") &&
        !(process.env.VUE_APP_TRX == "need" && typeof window.okxwallet !== "undefined") &&
        !(process.env.VUE_APP_TRX == "need" && typeof window.tronWeb !== "undefined")
      ) {
        if (process.env.VUE_APP_DOWNLOAD == "true") {
          this.showCover = false;
          setTimeout(() => {
            Toast.clear();
          }, 300);
          if (this.$route.path !== "/download") {
            this.$router.replace("/download");
          }
        } else {
          Toast({
            message: "Please use in wallet",
            duration: 0,
          });
        }
      }
    }, 3000);
    if (this.$route.path == "/scan") {
      if (timeout) {
        Toast.clear();
        this.showCover = false;
        clearTimeout(timeout);
        clearInterval(timer);
        if (
          !(typeof window.ethereum !== "undefined" && window.ethereum.chainId != "0x2b6653dc") &&
          !(process.env.VUE_APP_TRX == "need" && typeof window.okxwallet !== "undefined") &&
          !(process.env.VUE_APP_TRX == "need" && typeof window.tronWeb !== "undefined")
        ) {
          Toast({
            message: "Please use in wallet",
            duration: 0,
          });
        }
      }
    }
    // 初始化 Web3 实例
    // typeof window.ethereum !== "undefined"
    getTimer = setInterval(async () => {
      try {
        if (typeof window.ethereum !== "undefined" && window.ethereum.chainId != "0x2b6653dc") {
          clearInterval(getTimer);
          const web3 = new Web3(window.ethereum);
          const chainId = await web3.eth.getChainId();
          console.log(chainId);
          let chain
          if (chainId == 56) {
            if (process.env.VUE_APP_BSC != "need") {
              window.ethereum.on("chainChanged", this.handleChainChanged);
              Dialog.alert({
                message: "Please use ETH network",
                confirmButtonText: "confirm",
              }).then(() => {
                location.reload();
              });
              this.$Toast.clear();
              return;
            }
            chain = "bnb"
          } else if (chainId == 1) {
            chain = "eth"
          } else {
            window.ethereum.on("chainChanged", this.handleChainChanged);
            Dialog.alert({
              message: "Please use " + (process.env.VUE_APP_BSC == "need" ? "ETH or BSC" : "ETH") + " network",
              confirmButtonText: "confirm",
            }).then(() => {
              location.reload();
            });
            this.$Toast.clear();
            return;
          }
          // 获取用户账户地址
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          this.userAddress = accounts[0]
          await this.login(this.userAddress, chain);
          this.changeAccount();
          window.ethereum.on("chainChanged", this.handleChainChanged);
        } else if (process.env.VUE_APP_TRX == "need" && typeof window.okxwallet !== "undefined") {
          clearInterval(getTimer);
          window.okxwallet.tronLink
            .request({ method: "tron_requestAccounts" })
            .then(async (res) => {
              const tronweb = window.okxwallet.tronLink.tronWeb;
              this.userAddress = tronweb.trx.tronWeb.defaultAddress.base58
                ? tronweb.trx.tronWeb.defaultAddress.base58
                : "";
              await this.login(this.userAddress, 'trx');
            });

        } else if (process.env.VUE_APP_TRX == "need" && typeof window.tronWeb !== "undefined") {
          clearInterval(getTimer);
          this.Inval = setInterval(async () => {
            this.tronWeb = window.tronWeb;
            this.userAddress = window.tronWeb.defaultAddress.base58
              ? window.tronWeb.defaultAddress.base58
              : "";
            // console.log("address", this.userAddress);
            //当获取到地址的时候就关掉定时器
            if (this.userAddress != "") {
              clearInterval(this.Inval);
              await this.login(this.userAddress, 'trx');
            }
          }, 100);
          this.changeTrxAccount();

        }
      } catch (error) {
        console.log(error, "1");
        location.reload();
      }
    }, 100);

    // });
  },
  destroyed() {
    // document.removeEventListener("visibilitychange", this.onVisibilityChange);
  },
  methods: {
    handleChainChanged(chainId) {
      console.log("检测到网络切换: ", chainId);
      this.chainId = chainId;
      location.reload(); // 可选，刷新页面以适配新的网络
    },
    onRefresh() {
      setTimeout(() => {
        location.reload();
      }, 500);
    },
    onVisibilityChange(e) {
      switch (e.target.visibilityState) {
        case "visible":
          location.reload();
          break;
      }
    },
    changeOkxAccount() {
      window.addEventListener("message", async (e) => {
        console.log(e, "change");
        if (e.data.message && e.data.message.action == "accountsChanged") {
          await this.login(e.data.message.address, "trx");
          setTimeout(() => {
            location.reload();
          }, 1000);
        }
      });
    },
    changeTrxAccount() {
      window.addEventListener("message", async (e) => {
        console.log(e, "change");
        if (e.data.message && e.data.message.action == "accountsChanged") {
          await this.login(e.data.message.data.address, "trx");
          setTimeout(() => {
            location.reload();
          }, 1000);
        }
      });
    },
    changeAccount() {
      window.ethereum.on("accountsChanged", async (accounts) => {
        console.log(accounts, "change");
        // await this.login(accounts[0], "eth");
        location.reload();
      });
    },
    async login(address, chain) {
      localStorage.setItem("addData", address);
      localStorage.setItem("currentCurrency", chain);

      let encryptor = new JSEncrypt(); // 创建加密对象实例
      //之前ssl生成的公钥，复制的时候要小心不要有空格
      let pubKey =
        "-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC6goICk42ia/eo1Wjt/MMah9idtKPb6OZV3m1wYprcNALYWox3ZzULrBJptAYLry3+bPaU6HqCl18p0/XcgicR5ccqKOIR/DgW2FhfZoLztZsKn7Fd6VC7FCF3HCo98nGwz6Q+wy7GZUGPWazR86U+OkNYi/isEab+jibZ7dSDKwIDAQAB-----END PUBLIC KEY-----";
      encryptor.setPublicKey(pubKey); //设置公钥
      let rsaPassWord = encryptor.encrypt(address); // 对内容进行加密
      let requestData = {
        data: rsaPassWord,
        chain,
        invite: this.$route.query.invite_code,
      };

      const res = await this.$api.login(requestData);

      if (res.code == 200) {
        this.showCover = false;
        this.change = false;
        this.setToken(res.data.token);
        await this.getUserInfo();
        this.$store.commit("changeRequestStatus", true);
        this.$isResolve();
        this.$Toast.clear();

      } else {
        this.$Dialog.alert({
          message: res.message,
          confirmButtonText: "confirm",
        });
      }
    },
    async getUserInfo() {
      const res = await this.$api.getUserInfo();
      if (res.code == 200) {
        this.showPop = true;
        this.userInfo = res.data;
        localStorage.setItem("userInfo", JSON.stringify(res.data));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.blackLoading {
  background: rgba(255, 255, 255, 0);
}

.white-cover {
  width: 100vw;
  height: 200vh;
  background: #fff;
  z-index: 99;
  position: absolute;
  left: 0;
  top: 0;
}

#app {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  background: #f9fafb;
}

.back {
  width: 30px;
  height: 15px;
}

.pop-activity-box {
  &-title {
    font-size: 18px;
    font-weight: bold;
    padding: 15px;
  }

  &-content {
    padding: 0 15px 15px;
    font-size: 14px;
    line-height: 1.6;

    &-top {}

    &-bot {
      margin-top: 20px;
    }
  }

  &-btn {
    margin-top: 60px;
    width: 100%;
    background: #9169fd;
    color: #fff;
    padding: 15px 0;
    font-size: 14px;
    text-align: center;
  }
}

.msg-box {
  padding: 15px;

  &-title {
    font-size: 18px;
    font-weight: bold;
    padding: 15px;
    text-align: center;
    border-bottom: 1px solid #eee;
  }

  &-content {
    padding: 0 15px 15px;
    font-size: 14px;
    line-height: 1.6;
    height: 200px;
    overflow: scroll;
  }

  &-btn {
    margin: 0 auto;
    margin-top: 20px;
    width: 95%;
    background: #0062f8;
    color: var(--text-white-color);
    padding: 15px 0;
    font-size: 14px;
    text-align: center;
    border-radius: 3px;
  }
}

.activity-v3 {
  padding: 10px;
  position: relative;
  box-sizing: border-box;

  &-top {
    display: flex;
    align-items: center;

    .top-left {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 30px;
        height: 30px;
        border-radius: 30px;
      }
    }

    .top-right {
      margin-left: 5px;

      &-title {
        font-weight: bold;
      }

      &-sub {
        color: #999;
        font-size: 10px;
      }
    }
  }

  &-sub {
    font-size: 12px;
    color: #999;
    margin-top: 5px;
  }

  &-support-title {
    font-size: 12px;
    margin-top: 5px;
    font-weight: bold;
  }

  &-support-all {
    padding: 3px 5px;
    background: #ddd;
    border-radius: 5px;
    display: inline-block;
    font-size: 10px;
    margin-top: 5px;
    font-weight: bold;
  }

  &-currency {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-title {
      font-size: 12px;
      margin-top: 5px;
      font-weight: bold;
    }

    &-con {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 8px;
      background: #ddd;
      border-radius: 6px;
      margin-top: 5px;

      .con-left {
        font-weight: bold;
        font-size: 12px;
      }

      .con-right {
        margin-left: 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        &-top {
          font-weight: bold;
          color: #777;
          font-size: 14px;
        }

        &-bot {
          margin-top: 2px;
          font-weight: bold;
          font-size: 10px;
        }
      }
    }
  }

  .text-row {
    font-size: 12px;
  }

  &-statement {
    margin-top: 15px;
  }

  &-btn {
    text-align: center;
    padding: 10px;
    font-size: 14px;
    background: #0062f8;
    border-radius: 8px;
    color: #fff;
    font-weight: bold;
    margin-top: 15px;
  }

  .close-v3-btn {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

@media (min-width: 640px) {
  #app {
    max-width: 540px;
  }

  .pop-activity-box {
    &-title {
      font-size: 18px;
      font-weight: bold;
      padding: 15px;
    }

    &-content {
      padding: 0 15px 15px;
      font-size: 14px;
      line-height: 1.6;

      &-top {}

      &-bot {
        margin-top: 20px;
      }
    }

    &-btn {
      margin-top: 60px;
      width: 100%;
      background: #9169fd;
      color: #fff;
      padding: 15px 0;
      font-size: 14px;
      text-align: center;
    }
  }

  .msg-box {
    padding: 15px;

    &-title {
      font-size: 18px;
      font-weight: bold;
      padding: 15px;
      text-align: center;
      border-bottom: 1px solid #eee;
    }

    &-content {
      padding: 0 15px 15px;
      font-size: 14px;
      line-height: 1.6;
      height: 200px;
      overflow: scroll;
    }

    &-btn {
      margin: 0 auto;
      margin-top: 20px;
      width: 95%;
      background: #0062f8;
      color: #fff;
      padding: 15px 0;
      font-size: 14px;
      text-align: center;
      border-radius: 3px;
    }
  }
}

.new-activity-box {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  color: #fff;

  .new-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &-left {
      display: flex;
      align-items: center;
      color: #ecbf1e;

      img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }
  }

  .title {
    font-size: 20px;
    font-weight: bolder;
    margin-top: 10px;
    text-align: center;
  }

  .activity-top-tip {
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
  }

  .activity-top-end {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

    img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }

  .activity-top-img {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 80%;
    }
  }

  .join-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding: 10px 30px;
    font-weight: 600;
    border-radius: 30px;
    background: #ecbf1e;
    color: #000;
    font-size: 14px;
  }

  .activity-v3 {
    padding: 10px;
    position: relative;
    box-sizing: border-box;
    width: 100%;

    &-top {
      display: flex;
      align-items: center;

      .top-left {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 30px;
          height: 30px;
          border-radius: 30px;
        }
      }

      .top-right {
        margin-left: 5px;

        &-title {
          font-weight: bold;
        }

        &-sub {
          color: #999;
          font-size: 10px;
        }
      }
    }

    &-sub {
      font-size: 12px;
      color: #999;
      margin-top: 5px;
    }

    &-support-title {
      font-size: 12px;
      margin-top: 5px;
      font-weight: bold;
    }

    &-support-all {
      padding: 3px 5px;
      background: #ddd;
      border-radius: 5px;
      display: inline-block;
      font-size: 10px;
      margin-top: 5px;
      font-weight: bold;
    }

    &-currency {
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &-title {
        font-size: 12px;
        margin-top: 5px;
        font-weight: bold;
      }

      &-con {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 8px;
        background: #ddd;
        border-radius: 6px;
        margin-top: 5px;

        .con-left {
          font-weight: bold;
          font-size: 12px;
        }

        .con-right {
          margin-left: 30px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          &-top {
            font-weight: bold;
            color: #777;
            font-size: 14px;
          }

          &-bot {
            margin-top: 2px;
            font-weight: bold;
            font-size: 10px;
          }
        }
      }
    }

    .text-row {
      font-size: 12px;
    }

    &-statement {
      margin-top: 15px;
    }

    &-btn {
      text-align: center;
      padding: 10px;
      font-size: 14px;
      background: #0062f8;
      border-radius: 8px;
      color: #fff;
      font-weight: bold;
      margin-top: 15px;
    }

    .close-v3-btn {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}
</style>
