export default {
  ["pulling_text"]: "Tirare verso il basso per aggiornare...",
  ["loosing_text"]: "Rilascia per aggiornare...",
  "receive": "Ricevi",
  "mining_pool": "Pool di Mining",
  "account": "Account",
  "pool_data": "Dati del Pool",
  "total_output": "Produzione Totale",
  "valid_node": "Nodo Valido",
  "participant": "Partecipante",
  "user_revenue": "Ricavi dell'Utente",
  "activity": "Attività",
  "mining": "Mining",
  "liquidity": "reddito da mining di liquidità",
  "user_output": "Produzione dell'Utente",
  "address": "Indirizzo",
  "quantity": "Quantità",
  "help_center": "Centro Assistenza",
  "hep_center_tip": "spero che ti sia d'aiuto",
  "question1": "Come posso partecipare?",
  "answer1": "Per partecipare al mining di liquidità senza perdite e non garantito, è necessario pagare una commissione per il minatore ETH per ricevere il voucher e un indirizzo di portafoglio ETH deve essere richiesto solo una volta. Dopo il successo, le autorizzazioni per il mining si apriranno automaticamente.",
  "question2": "Come posso ritirare denaro?",
  "answer2": "Puoi convertire le monete prodotte giornalmente in USDT e poi iniziare un prelievo. Il prelievo di USDT sarà inviato automaticamente all'indirizzo del portafoglio che hai aggiunto al nodo, e altri indirizzi non sono supportati.",
  "question3": "Come viene calcolato il reddito?",
  "answer3": "Quando ti unisci con successo, il contratto intelligente inizia a calcolare il tuo indirizzo tramite il nodo e inizia a calcolare il reddito.",
  "audit_report": "Rapporto di Audit",
  "audit_report_tip": "abbiamo un rapporto di audit sicuro",
  "partner": "Partner",
  "partner_tip": "il nostro partner commerciale",
  "my_account": "Il mio account",
  "mining_status": "Stato del Mining",
  "wallet_balance": "Saldo del Portafoglio",
  "exchangeable": "Scambiabile",
  "withdrawable": "Prelevabile",
  "share_dividends": "Dividendi Azionari",
  "my_level": "Il mio Livello",
  "level": "Livello",
  "hash_rate": "Tasso di Hash",
  "output_interest": "Interesse di Produzione",
  "output_interest_rate": "Tasso di Interesse di Produzione",
  "output_interest_cycle": "Ciclo di Interesse di Produzione",
  "level_details": "Dettagli del Livello",
  "pair_price": "Prezzo della Coppia",
  "exchange": "Scambia",
  "withdraw": "Preleva",
  "redeem_all": "Riscatta tutto",
  "total_balance": "Saldo Totale",
  "record": "Registrazione",
  "shared": "Condiviso",
  "output": "Produzione",
  "back": "Indietro",
  "no-data": "Nessun dato",
  "received": "Ricevuto",
  "transfer": "Trasferisci",
  "share_friends": "Condividi con gli Amici",
  "my_share_link": "Il mio Link di Condivisione",
  "copy": "Copia",
  "share_tip": "Invia il tuo link di invito, gli amici si uniscono al nodo tramite il tuo link e riceverai generose ricompense in token",
  "join_tip": "Ti sei unito",
  "copySuccess": "Link copiato",
  "apply_mining_pool_rewards": "Richiedi le Ricompense del Pool di Mining",
  "standard": "Standard",
  "required": "Richiesto",
  "countdown": "Conto alla rovescia",
  "host_day": "Giorno",
  "reward": "Ricompensa",
  "i_know": "Lo so",
  "popup_miniing_title": "Richiedi le Ricompense del Pool di Mining",
  "popup_miniing_conn1": "Contratto Intelligente ERC-20",
  "popup_miniing_conn2": "Il pool di mining del contratto intelligente ERC-20 sta per iniziare il prossimo evento di condivisione ETH. Richiedi di unirti ora. Dopo l'inizio dell'evento, i fornitori di liquidità possono condividere più ricompense",
  "popup_miniing_btn": "Richiedi Ricompense",
  "recharge": "Ricarica",
  "recharge_currency": "Indirizzo di deposito",
  "recharge_scan code transfer": "Salva QR",
  "recharge_amount": "Importo del deposito",
  "please enter": "Per favore inserisci",
  "recharge_upload": "Clicca per caricare le immagini",
  "confirm": "Conferma",
  "rachange_link_coinbase": "Canale di ricarica ufficiale di Coinbase",
  "rachange_link_binance": "Canale di ricarica ufficiale di Binance",
  "rachange_link_huobi": "Canale di ricarica ufficiale di Huobi",
  "cancel": "Annulla",
  "addressCopySuccess": "Indirizzo copiato",
  "recharge_record": "Storico delle ricariche",
  "all": "Tutto",
  "processing": "In sospeso",
  "credited": "Riuscito",
  "fail": "Fallito",
  "amount": "Importo",
  "state": "Stato",
  "time": "Tempo",
  "record_tip": "Nota",
  "activity-top-title": "Ricompense Airdrop ETH",
"activity-top-big-title": "1 Milione di Ricompense Airdrop ETH in Arrivo",
"activity-top-tip": "Completa semplici compiti sulla pagina del nodo del pool di mining del tuo progetto di rendimento DeFi per condividere un'enorme ricompensa airdrop.",
"activity-end": "Attività Terminata",
"activity-join": "Partecipa all'Attività",
}