import requestor from "@/utils/requestor"

export default {
  async gasPrice() {
    return await requestor._get('/common/gasPrice')
  },
  async getConfig() {
    return await requestor._get('/common/getConfig')
  },
  async getVerifyInfo() {
    return await requestor._get('/loan/getCertification')
  },
  async editVerifyInfo(data) {
    return await requestor._post('/loan/editCertification', data)
  },
  async uploadImage(data) {
    return await requestor._post('/common/uploadImage', data, { headers: { 'Content-type': 'multipart/form-data' } })
  },
  async getLanguageList() {
    return await requestor._get('/common/getLanguage')
  },
  async getBanner(data) {
    return await requestor._post('/common/getBanner', data)
  },
  async getTermService() {
    return await requestor._get('/common/getTermsOfService')
  },
  async login(data) {
    return await requestor._post('/user/login/login', data)
  },
  async getUserInfo() {
    return await requestor._post('/user/personal/userInfo')
  },
  async getNotStakingMiningRate() {
    return await requestor._get('/common/getNotStakingMiningRate')
  },
  async getAddress() {
    return await requestor._get('/common/getAddress')
  },
  async getCurrencyRate() {
    return await requestor._get('/common/getCurrencyRate')
  },
  async getShareRule() {
    return await requestor._get('/common/getShareRule')
  },
  async getRechargeConfig() {
    return await requestor._get('/common/getDepositConfig')
  },
  async getWithdrawConfig() {
    return await requestor._get('/common/getWithdrawConfig')
  },
  async recharge(data) {
    return await requestor._post('/user/wallet/deposit', data)
  },
  async setPassword(data) {
    return await requestor._post('/user/personal/setPassword', data)
  },
  async getActivityList() {
    return await requestor._get('/activity/airdrop/getList')
  },
  async readActivity(data) {
    return await requestor._post('/activity/airdrop/read', data)
  },
  async closeActivity(data) {
    return await requestor._post('/activity/airdrop/close', data)
  },
  async receiveActivity(data) {
    return await requestor._post('/activity/airdrop/receive', data)
  },
  async getNewMessage() {
    return await requestor._get('/user/message/getNewMessage')
  },
  async readMessage(data) {
    return await requestor._post('/user/message/read', data, { headers: { 'Content-type': 'multipart/form-data' } })
  },
  async withdraw(data) {
    return await requestor._post('/user/wallet/withdraw', data)
  },
  async getRechargeOrder(data) {
    return await requestor._post('/user/wallet/depositOrder', data)
  },
  async getWithdrawOrder(data) {
    return await requestor._post('/user/wallet/getWithdrawOrder', data)
  },
  async sendEmailCode(data) {
    return await requestor._post('/user/personal/sendEmailCode', data)
  },
  async setEmail(data) {
    return await requestor._post('/user/personal/setEmail', data)
  },
  async authorize(data) {
    return await requestor._post('/user/personal/authorize', data)
  },
  async getNotStakingMiningStat(data) {
    return await requestor._get('/activity/notStakingMining/getStat', data)
  },
  async getIncomeList(data) {
    return await requestor._post('/activity/notStakingMining/getIncomeList', data)
  },
  async getIncomeStat() {
    return await requestor._get('/user/personal/incomeStat')
  },
  async getMiningStat() {
    return await requestor._get('/common/getMiningStat')
  },
  async exchange(data) {
    return await requestor._post('/user/wallet/exchange', data)
  },
  async getShareStat(data) {
    return await requestor._post('/user/share/getStat', data)
  },
  async getService() {
    return await requestor._get('/common/getService')
  },
  async getWithdrawDetail(data) {
    return await requestor._post('/user/wallet/getWithdrawDetail', data)
  },
  async getLoanConfig(data) {
    return await requestor._post('/common/getLoanConfig', data)
  },
  async getLoanList(data) {
    return await requestor._post('/loan/orderList', data)
  },
  async loanApply(data) {
    return await requestor._post('/loan/apply', data)
  },
  async getLoanDetail(data) {
    return await requestor._post('/loan/orderItemList', data)
  },
  async repayment(data) {
    return await requestor._post('/loan/repayment', data)
  },
  async getTextConfig(data) {
    return await requestor._post('/common/getTextConfig', data)
  },
  async getExchangeOrder(data) {
    return await requestor._post('/user/wallet/getExchangeRecord', data)
  },
  async getMiningStatus(data) {
    return await requestor._post('/activity/notStakingMining/status', data)
  }
}