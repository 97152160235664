import axios from 'axios'
import md5 from 'crypto-js/md5'

class RequestManager {
  constructor() {
    this.container = new Map();
  }

  _key(data) {
    const base = data.baseURL;
    let url = data.url
    url = base + url.replace(base, "");

    return md5(
      JSON.stringify([data.method, url, data.params, data.data])
    ).toString()
  }

  record(request, cancel) {
    this.container.set(this._key(request), cancel);
  }

  forget(request) {
    this.container.delete(this._key(request));
  }

  cancel(request) {
    const key = this._key(request);
    const cancel = this.container.get(key);
    if(cancel != undefined) {
      cancel("cancel for repeateing");
      this.container.delete(key)
    }
  }

  cancelAll() {
    for(let [_, cancel] of this.container) {
      cancel("cancel for all");
    }

    this.container.clear()
  }
}

const requestManager = new RequestManager()

const instance = axios.create({
  timeout: 30000,
  withCredentials: false
});

instance.cancelAll = requestManager.cancelAll.bind(requestManager)

instance.errorHandle = {
  400: undefined,
  401: undefined,
  404: undefined,
  500: undefined,
  timeout: undefined,
  network: undefined
};

instance.setBaseUrl = function(value) {
  this.defaults.baseURL = value
};

instance.setToken = function(value) {
  this.defaults.headers.common['Authorization'] = value;
};

instance.setLanguage = function(value) {
  this.defaults.headers.common['language'] = value;
};

instance._get = async function(url, config) {
  const { data } = await this.get(url, config);
  return data
}

instance._post = async function(url, body, config) {
  const { data } = await this.post(url, body, config);
  return data;
};

instance.interceptors.request.use(
  function(config) {
    // requestManager.cancel(config);
    config.cancelToken = new axios.CancelToken(cancel => {
      requestManager.record(config, cancel);
    })

    this.atBegin && this.atBegin();

    return config;
  }.bind(instance),
  error => {
    return Promise.reject(error)
  }
);

instance.interceptors.response.use(
  function(response) {
    requestManager.forget(response.config);

    this.atOver && this.atOver();
    return response
  }.bind(instance),
  function(error) {
    let handle = undefined;

    if(error.response != undefined) {
      handle = this.errorHandle[error.response.status];
    }
    
    if(error.message.startsWith("timeout")) {
      handle = this.errorHandle.timeout;
    }

    if(error.message.startsWith("Network")) {
      handle = this.errorHandle.network
    }

    handle && handle(error);

    this.atOver && this.atOver();

    console.log("error",error)
    // Promise.reject(error)
    return error.response;
  }.bind(instance)
);

export default instance;