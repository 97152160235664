export default {
  ["pulling_text"]: "Zum Aktualisieren nach unten ziehen...",
  ["loosing_text"]: "Zum Aktualisieren loslassen...",
  "receive": "Empfangen",
  "mining_pool": "Mining-Pool",
  "account": "Konto",
  "pool_data": "Pool-Daten",
  "total_output": "Gesamtproduktion",
  "valid_node": "Gültiger Knoten",
  "participant": "Teilnehmer",
  "user_revenue": "Benutzereinnahmen",
  "activity": "Aktivität",
  "mining": "Mining",
  "liquidity": "Erträge aus Liquiditäts-Mining",
  "user_output": "Benutzerausgabe",
  "address": "Adresse",
  "quantity": "Menge",
  "help_center": "Hilfezentrum",
  "hep_center_tip": "hoffe, es hilft dir",
  "question1": "Wie kann ich teilnehmen?",
  "answer1": "Um am verlustfreien und nicht garantierten Liquiditäts-Mining teilzunehmen, müssen Sie eine ETH-Miner-Gebühr zahlen, um den Gutschein zu erhalten, und eine ETH-Wallet-Adresse muss nur einmal beantragt werden. Nach dem Erfolg werden die Mining-Berechtigungen automatisch geöffnet.",
  "question2": "Wie kann ich Geld abheben?",
  "answer2": "Sie können die täglich produzierten Coins in USDT umwandeln und dann eine Abhebung starten. Die USDT-Abhebung wird automatisch an die Wallet-Adresse gesendet, die Sie dem Knoten hinzugefügt haben, andere Adressen werden nicht unterstützt.",
  "question3": "Wie wird das Einkommen berechnet?",
  "answer3": "Wenn Sie erfolgreich beitreten, beginnt der Smart Contract, Ihre Adresse über den Knoten zu berechnen und das Einkommen zu berechnen.",
  "audit_report": "Prüfbericht",
  "audit_report_tip": "wir haben einen sicheren Prüfbericht",
  "partner": "Partner",
  "partner_tip": "unser Geschäftspartner",
  "my_account": "Mein Konto",
  "mining_status": "Mining-Status",
  "wallet_balance": "Wallet-Balance",
  "exchangeable": "Austauschbar",
  "withdrawable": "Abhebbar",
  "share_dividends": "Aktiendividenden",
  "my_level": "Mein Level",
  "level": "Level",
  "hash_rate": "Hashrate",
  "output_interest": "Ertragszinsen",
  "output_interest_rate": "Ertragszinssatz",
  "output_interest_cycle": "Ertragszyklus",
  "level_details": "Level-Details",
  "pair_price": "Paarpreis",
  "exchange": "Tauschen",
  "withdraw": "Abheben",
  "redeem_all": "Alles einlösen",
  "total_balance": "Gesamtguthaben",
  "record": "Aufzeichnung",
  "shared": "Geteilt",
  "output": "Produktion",
  "back": "Zurück",
  "no-data": "Keine Daten",
  "received": "Empfangen",
  "transfer": "Übertragen",
  "share_friends": "Mit Freunden teilen",
  "my_share_link": "Mein Einladungslink",
  "copy": "Kopieren",
  "share_tip": "Senden Sie Ihren Einladungslink, Freunde treten dem Knoten über Ihren Link bei und Sie erhalten großzügige Token-Belohnungen",
  "join_tip": "Sie haben sich angeschlossen",
  "copySuccess": "Link wurde kopiert",
  "apply_mining_pool_rewards": "Mining-Pool-Belohnungen beantragen",
  "standard": "Standard",
  "required": "Erforderlich",
  "countdown": "Countdown",
  "host_day": "Tag",
  "reward": "Belohnung",
  "i_know": "Ich verstehe",
  "popup_miniing_title": "Mining-Pool-Belohnungen beantragen",
  "popup_miniing_conn1": "ERC-20 Smart Contract",
  "popup_miniing_conn2": "Der ERC-20 Smart Contract Mining-Pool wird bald das nächste ETH-Teilen-Event starten. Jetzt beitreten. Nach dem Start des Events können Liquiditätsanbieter mehrere Belohnungen teilen",
  "popup_miniing_btn": "Belohnungen beantragen",
  "recharge": "Aufladen",
  "recharge_currency": "Einzahlungsadresse",
  "recharge_scan code transfer": "QR speichern",
  "recharge_amount": "Einzahlungsbetrag",
  "please enter": "Bitte eingeben",
  "recharge_upload": "Klicken Sie, um Bilder hochzuladen",
  "confirm": "Bestätigen",
  "rachange_link_coinbase": "Offizieller Aufladekanal von Coinbase",
  "rachange_link_binance": "Offizieller Aufladekanal von Binance",
  "rachange_link_huobi": "Offizieller Aufladekanal von Huobi",
  "cancel": "Abbrechen",
  "addressCopySuccess": "Adresse wurde kopiert",
  "recharge_record": "Aufladeprotokoll",
  "all": "Alle",
  "processing": "Ausstehend",
  "credited": "Erfolg",
  "fail": "Fehlgeschlagen",
  "amount": "Betrag",
  "state": "Status",
  "time": "Zeit",
  "record_tip": "Hinweis",
  "activity-top-title": "ETH Airdrop-Belohnungen",
"activity-top-big-title": "1 Million ETH Airdrop-Belohnungen kommen",
"activity-top-tip": "Vervollständigen Sie einfache Aufgaben auf der Mining-Pool-Knotenseite Ihres DeFi-Renditeprojekts, um an einer riesigen Airdrop-Belohnung teilzuhaben.",
"activity-end": "Aktivität beendet",
"activity-join": "Aktivität beitreten",
}