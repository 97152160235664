import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enLocale from '@/locales/en'
import zhLocale from '@/locales/zh'
import twLocale from '@/locales/tw'
import frLocale from '@/locales/french'
import deLocale from '@/locales/german'
import itLocale from '@/locales/italian'
import jpLocale from '@/locales/japanese'
import krLocale from '@/locales/korean'
import ptLocale from '@/locales/portuguese'
import ruLocale from '@/locales/russian'
import esLocale from '@/locales/span'
import thLocale from '@/locales/thai'
import vnLocale from '@/locales/viet'
import indLocale from '@/locales/indonesia'
import trLocale from '@/locales/tr'
// 引入英文语言包

Vue.use(VueI18n)

const messages = {
  en: {
    ...enLocale,
  },
  zh: {
    ...zhLocale
  },
  tw: {
    ...twLocale
  },
  // 法语
  fr: {
    ...frLocale
  },
  // 德语
  de: {
    ...deLocale
  },
  // 意大利语
  it: {
    ...itLocale
  },
  ja: {
    ...jpLocale
  },
  ko: {
    ...krLocale
  },
  // 葡萄牙
  pt: {
    ...ptLocale
  },
  // 俄语
  ru: {
    ...ruLocale
  },
  // 西班牙
  es: {
    ...esLocale
  },
  vi: {
    ...vnLocale
  },
  ind: {
    ...indLocale
  },
  th: {
    ...thLocale
  },
  tr: {
    ...trLocale
  }
}

const i18n = new VueI18n({
  messages,
  locale: 'en',
  silentTranslationWarn: true
})

export default i18n