import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import("@/views/home")
  },
  {
    path: '/record',
    name: 'record',
    component: () => import("@/views/record")
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: () => import("@/views/recharge")
  },
  {
    path: '/recharge-record',
    name: 'rechargeRecord',
    component: () => import("@/views/recharge-record")
  },
  {
    path: '/download',
    name: 'download',
    component: () => import("@/views/download")
  },
  {
    path: '/scan',
    name: 'scan',
    meta: {
      auth: false
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/scan')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem(process.env.VUE_APP_TOKEN_KEY)
  NProgress.start()
  if (!token && to.meta.auth) {
    next('/host')
  } else {
    next()
  }

})

router.afterEach(() => {
  NProgress.done()
})

export default router
