import tools from './tools'

const filters = {
  dateFormat: (value, format= "yyyy-MM-dd") => {
    if(typeof value !== "object") {
      value = new Date(value);
    }

    return tools.dateFormat(value, format)
  }
}

export default {
  install: vue => {
    for(let key in filters) {
      vue.filter(key, filters[key]);
    }
  }
}